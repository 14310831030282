// PropertyDescription.js
import React from 'react';
import './PropertyDescription.css';


const PropertyDescription = ({ formData, handleChange, nextStep, prevStep }) => {
  return (
    <div className="form-step-container">
      <div className="form-header-container">
        <button className="back-button" onClick={prevStep}></button>
        <h2 className="form-title">Property Description</h2>
        <button className="next-button" onClick={nextStep}></button>
      </div>
      <textarea
        className="textarea-field" // Use the new class for the text area
        placeholder="EV charger, 10 mins from train station, solar roof, etc..."
        value={formData.description}
        onChange={handleChange('description')}
      />
    </div>
  );
};

export default PropertyDescription;
