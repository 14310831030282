// src/firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database'; // Import Realtime Database


const firebaseConfig = {
    apiKey: "AIzaSyCHt7Rke2Pmn1z-1lo3fQ5hOBicQjiywy0",
    authDomain: "realtorai-3955e.firebaseapp.com",
    projectId: "realtorai-3955e",
    storageBucket: "realtorai-3955e.appspot.com",
    messagingSenderId: "153961915962",
    appId: "1:153961915962:web:9c5a59c44f14a4567df703"
  };

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const database = firebase.database(); // Initialize Realtime Database

