// BasicAttributes.js
import React, { useState } from 'react';

const BasicAttributes = ({ formData, handleChange, nextStep, prevStep }) => {
  const [validation, setValidation] = useState({
    bedrooms: true,
    bathrooms: true,
    squareFootage: true,
    lotSize: true,
    yearBuilt: true,
  });

  const validateBedrooms = (value) => {
    return Number.isInteger(+value) && +value >= 0;
  };

  const validateBathrooms = (value) => {
    return (Number.isInteger(+value) || (value * 10) % 10 === 5) && +value >= 0;
  };

  const validateSquareFootage = (value) => {
    return !isNaN(value) && +value >= 0;
  };

  // const validateLotSize = (value) => {
  //   return !isNaN(value) && +value >= 0;
  // };
  const validateLotSize = (value) => {
    const numberValue = Number(value);
    return !isNaN(numberValue) && numberValue >= 0 && (Math.floor(numberValue * 100) === numberValue * 100);
  };
  
  const validateYearBuilt = (value) => {
    return /^\d{4}$/.test(value) && +value <= new Date().getFullYear();
  };

  const handleValidation = (name, value) => {
    let isValid = true;
    switch (name) {
      case 'bedrooms':
        isValid = validateBedrooms(value);
        break;
      case 'bathrooms':
        isValid = validateBathrooms(value);
        break;
      case 'squareFootage':
        isValid = validateSquareFootage(value);
        break;
      case 'lotSize':
        isValid = validateLotSize(value);
        break;
      case 'yearBuilt':
        isValid = validateYearBuilt(value);
        break;
      default:
        break;
    }
    setValidation({ ...validation, [name]: isValid });

  };

  const handleBlur = (name) => (event) => {
    const value = event.target.value;
    handleValidation(name, value);
  };

  return (
    <div className="form-step-container">
      <div className="form-header-container">
        <button className="back-button" onClick={prevStep}></button>
        <h2 className="form-title">Basic Attributes</h2>
        <button className="next-button" onClick={nextStep}></button>
      </div>
      <div className="input-container2">
        <label htmlFor="bedrooms" className="input-label2">Bedrooms</label>
        <input
          id="bedrooms"
          className={`input-field2 ${!validation.bedrooms ? 'invalid' : ''}`}
          type="number"
          placeholder="Number of bedrooms"
          value={formData.bedrooms}
          onChange={handleChange('bedrooms')}
          onBlur={handleBlur('bedrooms')}
          min="0"
        step="1"
        />
      </div>
      <div className="input-container2">
        <label htmlFor="bathrooms" className="input-label2">Bathrooms</label>
        <input
          id="bathrooms"
          className={`input-field2 ${!validation.bathrooms ? 'invalid' : ''}`}
          type="number"
          placeholder="Number of bathrooms"
          value={formData.bathrooms}
          onChange={handleChange('bathrooms')}
          onBlur={handleBlur('bathrooms')}
          min="0"
          step="0.5"
        />
      </div>
      <div className="input-container2">
        <label htmlFor="squareFootage" className="input-label2">Square Ft.</label>
        <input
          id="squareFootage"
          className={`input-field2 ${!validation.squareFootage ? 'invalid' : ''}`}
          type="number"
          placeholder="Square Footage"
          value={formData.squareFootage}
          onChange={handleChange('squareFootage')}
          onBlur={handleBlur('squareFootage')}
          min="0"
        step="1"
        />
      </div>
      <div className="input-container2">
        <label htmlFor="lotSize" className="input-label2">Lot Size</label>
        <input
          id="lotSize"
          className={`input-field2 ${!validation.lotSize ? 'invalid' : ''}`}
          type="number"
          placeholder="Lot Size (Acres)"
          value={formData.lotSize}
          onChange={handleChange('lotSize')}
          onBlur={handleBlur('lotSize')}
          min="0"
        step="0.01"
        />
      </div>
      <div className="input-container2">
        <label htmlFor="yearBuilt" className="input-label2">Year Built</label>
        <input
          id="yearBuilt"
          className={`input-field2 ${!validation.yearBuilt ? 'invalid' : ''}`}
          type="number"
          placeholder="Year Built"
          value={formData.yearBuilt}
          onChange={handleChange('yearBuilt')}
          onBlur={handleBlur('yearBuilt')}
          min="1800"
        step="1"
        />
      </div>
    </div>
  );
};

export default BasicAttributes;
