import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ListingDetail.css';
import expandIcon from '../images/arrow_down_filled2.png';
import collapseIcon from '../images/arrow_up_filled2.png';

const ListingDetail = () => {
    const { uid, listingId } = useParams();
    const [listingDetails, setListingDetails] = useState(null);
    const [isAddressVisible, setAddressVisible] = useState(false);
    const [isPropertyDetailsVisible, setPropertyDetailsVisible] = useState(false);
    const [isTypeVisible, setTypeVisible] = useState(false);
    const [isDetailsVisible, setDetailsVisible] = useState(false);

    const toggleAddressVisibility = () => {
        setAddressVisible(!isAddressVisible);
    };
    const togglePropertyDetailsVisibility = () => {
        setPropertyDetailsVisible(!isPropertyDetailsVisible);
    };
    const toggleTypeVisibility = () => {
        setTypeVisible(!isTypeVisible);
    }; const toggleDetailsVisibility = () => {
        setDetailsVisible(!isDetailsVisible);
    };

    useEffect(() => {
        const fetchListingDetails = async () => {
            try {
                const response = await fetch(`https://realtorai-3955e.uc.r.appspot.com/api/listings/${uid}/${listingId}`);
                const listingDetails = await response.json();
                setListingDetails(listingDetails);
            } catch (error) {
                console.error("Error fetching listing details: ", error);
            }
        };

        fetchListingDetails();
    }, [uid, listingId]);



    if (!listingDetails) {
        return <div>Loading...</div>;
    }

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(listingDetails.aiDescription)
            .then(() => {
                console.log('Text copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    return (
        <main className="listing-detail">
            <h1>Listing Details</h1>

            <div className="content-container">
                <div className="left-column">

                    <div className="form-step-container2">
                        <div className="form-header-container2">
                            <h2 className="form-title2">Address</h2>
                            <button onClick={toggleAddressVisibility} className="toggle-button">
                                <img
                                    src={isAddressVisible ? collapseIcon : expandIcon}
                                    alt={isAddressVisible ? 'Collapse' : 'Expand'}
                                />
                            </button>
                        </div>
                        {isAddressVisible && (
                            <div className='form-content-container'>
                                <div className="input-container">
                                    <label className="input-label">Street</label>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={listingDetails.street}
                                        disabled
                                    />
                                </div>
                                <div className="input-container">
                                    <label className="input-label">Unit</label>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={listingDetails.unit}
                                        disabled
                                    />
                                </div>
                                <div className="input-container">
                                    <label className="input-label">City</label>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={listingDetails.city}
                                        disabled
                                    />
                                </div>
                                <div className="input-container">
                                    <label className="input-label">State</label>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={listingDetails.state}
                                        disabled
                                    />
                                </div>
                                <div className="input-container">
                                    <label className="input-label">Zip Code</label>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={listingDetails.zipcode}
                                        disabled
                                    />
                                </div>
                            </div>
                        )}
                    </div>


                    <div className="form-step-container2">
                        <div className="form-header-container2">
                            <h2 className="form-title2">Property Details</h2>
                            <button onClick={togglePropertyDetailsVisibility} className="toggle-button">
                                <img
                                    src={isPropertyDetailsVisible ? collapseIcon : expandIcon}
                                    alt={isPropertyDetailsVisible ? 'Collapse' : 'Expand'}
                                />
                            </button>
                        </div>
                        {isPropertyDetailsVisible && (
                            <div className='form-content-container'>
                                <div className="input-container">
                                    <label className="input-label">Bedrooms</label>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={listingDetails.bedrooms}
                                        disabled
                                    />
                                </div>
                                <div className="input-container">
                                    <label className="input-label">Bathrooms</label>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={listingDetails.bathrooms}
                                        disabled
                                    />
                                </div>
                                <div className="input-container">
                                    <label className="input-label">Sq. Ft.</label>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={`${listingDetails.squareFootage} sq. ft.`}
                                        disabled
                                    />
                                </div>
                                <div className="input-container">
                                    <label className="input-label">Lot Size</label>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={`${listingDetails.lotSize} acres`}
                                        disabled
                                    />
                                </div>
                                <div className="input-container">
                                    <label className="input-label">Year Built</label>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={listingDetails.yearBuilt}
                                        disabled
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="form-step-container2">
                        <div className="form-header-container2">
                            <h2 className="form-title2">Transaction and Property Type</h2>
                            <button onClick={toggleTypeVisibility} className="toggle-button">
                                <img
                                    src={isTypeVisible ? collapseIcon : expandIcon}
                                    alt={isTypeVisible ? 'Collapse' : 'Expand'}
                                />
                            </button>
                        </div>
                        {isTypeVisible && (
                            <div className='form-content-container'>
                                <div className="input-container">
                                    <label className="input-label">Transaction</label>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={listingDetails.transactionType}
                                        disabled
                                    />
                                </div>
                                <div className="input-container">
                                    <label className="input-label">Property</label>
                                    <input
                                        className="input-field"
                                        type="text"
                                        value={listingDetails.propertyType}
                                        disabled
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="form-step-container2">
                        <div className="form-header-container2">
                            <h2 className="form-title2">Property Features</h2>
                            <button onClick={toggleDetailsVisibility} className="toggle-button">
                                <img
                                    src={isDetailsVisible ? collapseIcon : expandIcon}
                                    alt={isDetailsVisible ? 'Collapse' : 'Expand'}
                                />
                            </button>
                        </div>
                        {isDetailsVisible && (
                            <div className='form-content-container'>
                                <div className="textarea-display">
                                    {listingDetails.description}
                                </div>
                            </div>
                        )}
                    </div>


                </div>
                <div className="right-column">
                    <div className="ai-container">
                        <div className="form-header-container2">
                            <h2 className="form-title2">AI Description</h2>
                        </div>
                        <div className="ai-textarea-display">
                            {listingDetails.aiDescription}
                        </div>
                        <button className='copyButton' onClick={handleCopyToClipboard}>Copy</button>

                    </div>
                </div>
            </div>
        </main>
    );
};
export default ListingDetail;