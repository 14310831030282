// TransactionType.js
import React from 'react';
import './TransactionType.css';

const TransactionType = ({ formData, handleChange, nextStep, prevStep }) => {
  return (
    <div className="form-step-container">
      <div className="form-header-container-transaction">
        <button className="back-button" onClick={prevStep}></button>
        <h2 className="form-title">Transaction Type</h2>
        <button className="next-button" onClick={nextStep}></button>
      </div>
      <div className="radio-group">
        <input
          type="radio"
          id="for-sale"
          name="transactionType"
          value="For Sale"
          className="radio-button"
          checked={formData.transactionType === 'For Sale'}
          onChange={handleChange('transactionType')}
        />
        <label htmlFor="for-sale" className="radio-label">
          For Sale
        </label>

        <input
          type="radio"
          id="for-rent"
          name="transactionType"
          value="For Rent"
          className="radio-button"
          checked={formData.transactionType === 'For Rent'}
          onChange={handleChange('transactionType')}
        />
        <label htmlFor="for-rent" className="radio-label">
          For Rent
        </label>
      </div>
    </div>
  );
};

export default TransactionType;
