import axios from 'axios';

const mapboxAccessToken = 'pk.eyJ1IjoiYW5pcmJhbmsiLCJhIjoiY2xyZnRuaWdzMDUwbzJ0bzF5ZDRiY3p3YyJ9.0_NSPuoHYOCGE1K84qOYyA';

const getCoordinatesFromAddress = async (address) => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${mapboxAccessToken}`;

  try {
    const response = await axios.get(url);
    const data = response.data;
    if (data.features && data.features.length > 0) {
      const coordinates = data.features[0].center;
      return { lng: coordinates[0], lat: coordinates[1] };
    } else {
      throw new Error('No coordinates found for this address.');
    }
  } catch (error) {
    console.error('Error fetching coordinates:', error);
    return null;
  }
};

export default getCoordinatesFromAddress;
