import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Signup from './components/Signup';
import LandingPage from './components/LandingPage';
import ListingDetail from './components/ListingDetail'; // This is the new detail page component
import Subscribe from './components/Subscribe';
import FAQ from './components/FAQ';
import Contact from './components/Contact';
import Settings from './components/Settings';

import { auth } from './firebase'; // Adjust the path if necessary
import { signOut } from 'firebase/auth'; // Make sure to import signOut
import './App.css';

import { fetchUserData } from './api'; // Import the fetchUserData function


const App = () => {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Set loading to true initially
  const [userData, setUserData] = useState(null); // State to store user data

  const handleSignOut = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
      setIsUserAuthenticated(false);
      setUserData(null);
    }).catch((error) => {
      // An error happened.
      console.error('Sign Out Error:', error);
    });
  };

  const onLoginSuccess = () => {
    setIsUserAuthenticated(true);
  };
  const onSignupSuccess = () => { 
    setIsUserAuthenticated(true);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
        if (user) {
            try {
                const fetchedUserData = await fetchUserData(user.uid);
                setUserData(fetchedUserData);
                setIsUserAuthenticated(true);
            } catch (error) {
                console.error("Error: ", error);
            }
        } else {
            setIsUserAuthenticated(false);
            setUserData(null);
        }
        setLoading(false);
    });

    return () => unsubscribe();
}, []);


  if (loading) {
    return <div className="loader"></div>; // Circular spinning loader
  }

  return (
    <Router>
      <div className="App">
        <Header isLoggedIn={isUserAuthenticated} user={userData} onSignOut={handleSignOut} />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login isUserAuthenticated={isUserAuthenticated} onLoginSuccess={onLoginSuccess} />} />
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route path="/signup" element={<Signup isUserAuthenticated={isUserAuthenticated} onSignupSuccess={onSignupSuccess} />} />
          <Route path="/dashboard" element={isUserAuthenticated ? <Dashboard /> : <Navigate replace to="/login" />}/>
          <Route path="/subscribe" element={isUserAuthenticated ? <Subscribe /> : <Navigate replace to="/login" />} />
          <Route path="/listing/:uid/:listingId" element={<ListingDetail />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/settings" element={isUserAuthenticated ? <Settings /> : <Navigate replace to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
