import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { auth } from '../firebase'; 
import './Login.css';

const Login =  ({ isUserAuthenticated, onLoginSuccess })  => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Hook to navigate to different routes


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Set the persistence level before signing in
      await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL); // Or SESSION/NONE based on your requirement

      // Sign in the user with Firebase Authentication
      await auth.signInWithEmailAndPassword(email, password);

      onLoginSuccess();

      // Navigate to the dashboard after successful login
      navigate('/dashboard');
    } catch (error) {
      // Handle errors here, such as displaying a notification
      console.error('Login error:', error);
      alert(error.message);
    }
  };

  const handleGoogleLogin = async () => {
    // const provider = new GoogleAuthProvider();
    // try {
    //   await signInWithPopup(auth, provider);
    //   onLoginSuccess();
    //   navigate('/dashboard');
    // } catch (error) {
    //   console.error('Error during Google Login:', error);
    //   alert(error.message);
    // }

    const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            const userExistsResponse = await fetch(`https://realtorai-3955e.uc.r.appspot.com/api/checkUser/${user.uid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (userExistsResponse.ok) {
                const data = await userExistsResponse.json();
                if (data.userExists) {
                  onLoginSuccess();
                  navigate('/dashboard');
                  return;
                }
            }

            const response = await fetch('https://realtorai-3955e.uc.r.appspot.com/api/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: user.uid,
                    fullName: user.displayName,
                    email: user.email,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to save user data');
            }

            // setTimeout(() => {
                onLoginSuccess();
                navigate('/dashboard');
            // }, 2000);
        } catch (error) {
            console.error('Error during Google Sign-In:', error);
            alert(error.message);
        }
  };

  useEffect(() => {
    if (isUserAuthenticated) {
      navigate('/dashboard');
    }
  }, [isUserAuthenticated, navigate]);

  return (
    <div className="login-container">
      <div className="login-form-container">
        <h1 className="login-title">Log In</h1>
            <button className="google-login-btn" onClick={handleGoogleLogin}>Log in with Google</button>
        {/* <button className="apple-login-btn">Log in with </button> */}
        <div className="divider">— Or sign in with email —</div>

        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="login-input"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="login-input"
          />
          <div className="remember-me-container">
            <input type="checkbox" id="remember-me" defaultChecked />
            <label htmlFor="remember-me">Remember me</label>
          </div>
          <button type="submit" className="login-btn">Log in</button>
        </form>

        {/* <a href="https://apple.com" className="forgot-password-link">Forgot your password?</a> */}
        {/* <Link to="/signup" className="signup-linkHolder"><a href="#" className="sign-up-link"></a></Link> */}

        <Link to="/signup" className="sign-up-link">Don't have an account? Sign up</Link>

      </div>
    </div>
  );
};

export default Login;
