// PropertyAddress.js
import React, { useState } from 'react';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import { MdLocationOn } from 'react-icons/md';
import './PropertyAddress.css';

const mapboxClient = mbxGeocoding({ accessToken: 'pk.eyJ1IjoiYW5pcmJhbmsiLCJhIjoiY2xyZnRuaWdzMDUwbzJ0bzF5ZDRiY3p3YyJ9.0_NSPuoHYOCGE1K84qOYyA' });

const PropertyAddress = ({ formData, handleChange, setFormData, nextStep }) => {
  const [suggestions, setSuggestions] = useState([]);


  const states = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
    'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
    'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
    'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
  ];

  const handleStreetChange = async (event) => {
    handleChange('street')(event);
    const street = event.target.value;

    if (street.length > 2) {
      const response = await mapboxClient.forwardGeocode({
        query: street,
        autocomplete: true,
        countries: ['us'], // This restricts search results to the United States
        types: ['address'],
      }).send();

      if (response && response.body && response.body.features) {
        setSuggestions(response.body.features);
      }
    } else {
      setSuggestions([]);
    }
  };

  const selectSuggestion = (suggestion) => {
    const parts = suggestion.place_name.split(', ');

    // Basic parsing based on a US address format

    const streetAddress = parts[0];
    const cityAddress = parts[1] || '';
    const stateAddress = parts[2].substr(0, parts[2].length - 6)
    const zipCode = parts[2].substr(parts[2].length - 5);

    console.log(parts)
    console.log(streetAddress, cityAddress, stateAddress, zipCode);

    // Simulate event objects for the handleChange function

    setFormData(prevFormData => ({
      ...prevFormData,
      street: streetAddress,
      city: cityAddress,
      state: stateAddress,
      zipcode: zipCode
    }));

    // Clear suggestions after selection
    setSuggestions([]);

  };

  return (
    <div className="form-step-container">
      <div className="form-header-container">
        <button className="spacer-button" disabled></button>
        <h2 className="form-title">Property Address</h2>
        <button className="next-button" onClick={nextStep}></button>
      </div>

      <div className="input-container">
        <label className="input-label">Street</label>
        <input
          className="input-field"
          type="text"
          placeholder="Enter street"
          value={formData.street}
          onChange={handleStreetChange}
        />
      </div>
      {suggestions.length > 0 && (
        <ul className="suggestions">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => selectSuggestion(suggestion)}>
              <MdLocationOn className="suggestion-icon" />
              <div>
                <div className="suggestion-primary">{suggestion.place_name.split(',')[0]}</div>
                <div className="suggestion-secondary">{suggestion.place_name.split(',').slice(1).join(',')}</div>
              </div>
            </li>
          ))}
        </ul>
      )}

      <div className="input-container">
        <label className="input-label">Unit</label>
        <input
          className="input-field"
          type="text"
          placeholder="Unit (Optional)"
          value={formData.unit}
          onChange={handleChange('unit')}
        />
      </div>
      <div className="input-container">
        <label className="input-label">City</label>
        <input
          className="input-field"
          type="text"
          placeholder="City"
          value={formData.city}
          onChange={handleChange('city')}
        />
      </div>

      <div className="input-container">
        <label className="input-label">State</label>
        <select
          className="select-field input-field"
          value={formData.state}
          onChange={handleChange('state')}
        >
          {states.map((state, index) => (
            <option key={index} value={state}>{state}</option>
          ))}
        </select>
      </div>
    
      <div className="input-container">
        <label className="input-label">Zip Code</label>
        <input
          className="input-field"
          type="text"
          placeholder="Zip Code"
          value={formData.zipcode}
          onChange={handleChange('zipcode')}
        />
      </div>
    </div>
  );
};

export default PropertyAddress;
