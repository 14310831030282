// Settings.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserSubscriptionStatus, unsubscribeUser, resubscribeUser } from '../api'; // Make sure to implement these functions in your api.js
import './Settings.css';
import { auth } from '../firebase'; // Adjust the import path if necessary


const Settings = () => {
  const [subscription, setSubscription] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the subscription data when the component mounts
    const getSubscriptionData = async () => {
        const userUID = auth.currentUser.uid;
        const subscriptionData = await fetchUserSubscriptionStatus(userUID);
        console.log(subscriptionData.adsBasicOrPremium);
        if (subscriptionData.adsBasicOrPremium === undefined) {
          setSubscription(null);
        } else {
          setSubscription(subscriptionData);
        }
        
    };

    getSubscriptionData();
  }, []);

  const handleUnsubscribeClick = async () => {
    const userUID = auth.currentUser.uid;
    try {
      const response = await unsubscribeUser(userUID);
      if (response.success) {
        const updatedSubscriptionData = await fetchUserSubscriptionStatus(userUID);
        setSubscription(updatedSubscriptionData); // Update the state with the latest subscription data
        alert('You have successfully initiated the cancellation of your subscription. You won\'t be charged again.');
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      alert('Error during unsubscription: ' + error.message);
    }
  };

  const handleResubscribeClick = async () => {
    const userUID = auth.currentUser.uid;
    try {
      const response = await resubscribeUser(userUID);
      if (response.success) {
        const updatedSubscriptionData = await fetchUserSubscriptionStatus(userUID);
        setSubscription(updatedSubscriptionData); // Update the state with the latest subscription data
        alert('You have successfully resubscribes.');
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      alert('Error during resubscription: ' + error.message);
    }
  };

  const handleSubscribeClick = () => {
    navigate('/subscribe');
  };
  

  return (
    <div className="settings">
      <h1>Settings</h1>
      <div className="subscription-details">
        {subscription ? (
          <>
            <p>Subscription Plan: {subscription.adsBasicOrPremium}</p>
            <p>Status: {subscription.status}</p>
            <p>End date: {subscription.expiresAt}</p>
            <p>Cancelled: {subscription.cancelled ? 'Yes' : 'No'}</p>
            {subscription.cancelledAt && <p>Cancelled Date: {subscription.cancelledAt}</p>}
            {subscription.cancelled ? (
              <button onClick={handleResubscribeClick}>Resubscribe</button>
            ) : (
              <button onClick={handleUnsubscribeClick}>Unsubscribe</button>
            )}
          </>
        ) : (
          <>
            <p>You are not currently subscribed.</p>
            <button onClick={handleSubscribeClick}>Subscribe</button>
          </>
        )}
      </div>
    </div>
  );
};

export default Settings;
