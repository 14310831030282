import React from 'react';
import './FAQ.css';

const FAQ = () => {
  return (
    <main className='FAQ'>
        <h1>Frequently Asked Questions</h1>
        
        <h2 className='question-headings'>What is The Listly?</h2>
        <p>The Listly is a tool for real estate agents and individuals that allows them to post better listing
            descriptions for their properties. We use models such as GPT-3.5 and GPT-4 to better assist you in
            listing your property. With an addition of a new form of Vision AI, we also can take picture of the 
            home and use the details in the picture to make an even better description.
        </p>
        <h2 className='question-headings'>Why is this better?</h2>
        <p>Writing listing descriptions is a time consuming and tedious task. You could spend hours making the
            listing description and still not be satisfied with the result. Some people even hire freelance
            writers to help assist in this process at almost <strong>$80-$100</strong> per listing.
            With The Listly, you can get a better description in minutes for a fraction of the cost. All you have to
            do is input the address, basic property details, and upload some pictures and the AI will do the rest.
            The captivating description will surely interest potential buyers and help you sell your home faster
            and for more money!
        </p>
        <h2 className='question-headings'>How does it work?</h2>
        <p>Our website uses the latest in AI tech to analyze what you provide us and create the most compelling
            description for your property. Many real estate agents have already used our service and have seen
            incredible results compared to human written descriptions.
        </p>
        <h2 className='question-headings'>My question isn't here!</h2>
        <p>Feel free to email us at hello@thelistly.com</p>
    </main>
  );
};

export default FAQ;
