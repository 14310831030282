// PropertyPictures.js
import React, { useState } from 'react';
import './PropertyPictures.css';

const PropertyPictures = ({ formData, handleFileChange, prevStep, handleSubmit, subscriptionPlan }) => {
  const [imagePreviews, setImagePreviews] = useState([]);
  const [dragActive, setDragActive] = useState(false);

  let maxPhotos;

  switch (subscriptionPlan) {
    case 'ads':
      maxPhotos = 3;
      break;
    case 'basic':
      maxPhotos = 5;
      break;
    case 'premium':
      maxPhotos = 10;
      break;
    default:
      maxPhotos = 10; // Default value if none of the cases match
  }
    // console.log("maxPhotos: ", maxPhotos)

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length) {
      handleFiles(e.dataTransfer.files);
    }
  };

  const handleFiles = (files) => {

    if (files instanceof FileList) {
      files = Array.from(files);
    }

    if (files.target && files.target.files) {
      files = Array.from(files.target.files);
    }

    const validFiles = files.filter(file => 
      file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg"
    );

    if (validFiles.length !== files.length) {
      window.alert("It seems like you tried to upload an unsupported file type! Only PNG, JPG, and JPEG files are allowed.");
      console.log("error image");
      return;
      // Optionally, you can still process the valid files
    }

    const totalFilesCount = formData.pictures.length + files.length;

    // Check if the total number exceeds maxPhotos
    if (totalFilesCount > maxPhotos) {
      window.alert(`You can only upload a maximum of ${maxPhotos} images.`);
      console.log("error image")
      return; // Stop further processing
    }

    const totalFiles = [...formData.pictures, ...files].slice(0, maxPhotos);

    const newImageUrls = files.map(file => URL.createObjectURL(file));

    setImagePreviews(prevPreviews => [...prevPreviews, ...newImageUrls]);
    handleFileChange(totalFiles); // Assuming this function also updates formData.pictures
  };

  const removeImage = (index) => {
    // Remove the image preview
    const newPreviews = [...imagePreviews];
    newPreviews.splice(index, 1);
    setImagePreviews(newPreviews);

    // Remove the image from formData
    const updatedFiles = formData.pictures.filter((_, idx) => idx !== index);
    handleFileChange(updatedFiles);
  };


  return (

    <div className="form-step-container">
      <div className="form-header-container-pictures">
        <button className="back-button" onClick={prevStep}></button>
        <h2 className="form-title">Property Pictures</h2>
        <button className="submit-button" onClick={handleSubmit}></button>
      </div>
      <div className="max-photos-info">Maximum photos: {maxPhotos}</div>
      <div className="image-preview-container">
        {imagePreviews.map((preview, index) => (
          <div key={index} className="image-preview">
            <img
              src={preview}
              alt={`Preview ${index}`}
              onClick={() => window.open(preview, '_blank')}
            />
            <button onClick={() => removeImage(index)} className="delete-image-button">X</button>
          </div>
        ))}
      </div>

      <input
          type="file"
          id="file-upload"
          multiple
          onChange={handleFiles}
          accept=".png, .jpg, .jpeg" 
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            opacity: '0',
            cursor: 'pointer'
          }}
          hidden
        />

      <div
        className="drag-drop-container"
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
        onClick={() => document.getElementById('file-upload').click()}
      >
        {dragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
        <input
          type="file"
          id="file-upload"
          multiple
          onChange={handleFiles}
          accept=".png, .jpg, .jpeg" 
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            opacity: '0',
            cursor: 'pointer'
          }}
        />
      </div>

    </div>


  );
};

export default PropertyPictures;
