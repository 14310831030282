import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <main className='contact-us'>
        <h1>Contact Us</h1>
        
        <h2 className='contact-headings'>Hello 👋</h2>
        <p className="contactText">
            Have a question?<br></br>
            Have a suggestion?<br></br>
            Have a problem?<br></br>
            Please send us an email at <strong>hello@thelistly.com</strong>
        </p>
    </main>
  );
};

export default Contact;
