import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Subscribe.css';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { auth } from '../firebase'; // Adjust the import path if necessary
import { fetchUserData, payAsYouGoSubscription } from '../api';
//LIVE
const stripePromise = loadStripe('pk_live_51OZjujGyJ9uIkcUsMAtvq0FCcIOUyitO9M2onkMfgVp2khpoTzt9YY1zMijzaVdZTy3XXVInhfwuNhVQm63ATF3000CUVncLjK');

//TEST
// const stripePromise = loadStripe('pk_test_51OZjujGyJ9uIkcUsRoSAO1FeoTmRzPkig3V05nJtYetcc0scPxLHGpUKei7BK4BW6yrGON9SZmUvGXtYPHnt00fB002VKPXxTP');

const Subscribe = () => {
    const [isAnnual, setIsAnnual] = useState(false); // Default to monthly billing
    const navigate = useNavigate();

    const togglePricing = () => {
        setIsAnnual(!isAnnual);
    };

    const handleStartNow = async (plan) => {
        const billingCycle = isAnnual ? 'annual' : 'monthly';

        try {
            // Get the current Firebase user
            const user = auth.currentUser;
            const fetchedUserData = await fetchUserData(user.uid);
            const fullName = fetchedUserData ? fetchedUserData.fullName : '';

            if (user) {
                const response = await axios.post('https://realtorai-3955e.uc.r.appspot.com/create-checkout-session', {
                // const response = await axios.post('http://localhost:3001/create-checkout-session', {
                    plan,
                    billingCycle,
                    user_firebaseUID: user.uid,
                    userEmail: user.email,
                    userName: fullName
                });
                const sessionId = response.data.sessionId;

                const stripe = await stripePromise;
                await stripe.redirectToCheckout({ sessionId });
            } else {
                console.error('No authenticated user found');
                // Handle the case where there is no authenticated user
            }
        } catch (error) {
            console.error('Error redirecting to checkout:', error);
        }
    };

    const handlePayAsYouGo = async () => {
        const userUID = auth.currentUser.uid;
        try {
            const response = await payAsYouGoSubscription(userUID);
            if (response.success) {
                alert('You have successfully subscribed to pay as you go!');
                navigate('/dashboard');
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            alert('Error during resubscription: ' + error.message);
        }
    };

    return (
        <main className="subscribe">
            <h2>Pick a Plan</h2>
            <div className="toggle-container">
                <span>Monthly</span>
                <label className="switch">
                    <input type="checkbox" checked={isAnnual} onChange={togglePricing} />
                    <span className="slider round"></span>
                </label>
                <span>Annually</span>
            </div>
            <div className="pricing-container">
                <div className="plan">
                    <h3>{isAnnual ? '$3.99/listing' : '$3.99/listing'}</h3>
                    <p className="old-price">{isAnnual ? '$5.99/listing' : '$5.99/listing'}</p>
                    <ul>
                        <li>Basic AI</li>
                        <li>-----</li>
                        <li>Pay as you go.</li>
                        <li>Max 5 images/listing</li>
                        <li>Ads</li>
                        <li>-----</li>
                    </ul>
                    <button onClick={() => handlePayAsYouGo()}>Subscribe</button>
                </div>
                <div className="plan">
                    <h3>{isAnnual ? '$75/year' : '$7/month'}</h3>
                    <p className="old-price">{isAnnual ? '$108/year' : '$9/month'}</p>
                    <ul>
                        <li>Basic AI</li>
                        <li>-----</li>
                        <li>5 listings/month</li>
                        <li>Max 3 images/listing</li>
                        <li>Ads</li>
                        <li>Cancel anytime</li>
                    </ul>
                    <button onClick={() => handleStartNow('ads')}>Subscribe</button>
                </div>
                <div className="plan best-value">
                    <h3>{isAnnual ? '$108/year' : '$12/month'}</h3>
                    <p className="old-price">{isAnnual ? '$135/year' : '$15/month'}</p>
                    <ul>
                        <li>Basic AI</li>
                        <li>-----</li>
                        <li>10 listings/month</li>
                        <li>Max 5 images/listing</li>
                        <li>Ad free</li>
                        <li>Cancel anytime</li>
                    </ul>
                    <button onClick={() => handleStartNow('basic')}>Subscribe</button>
                    <div className="badge">BEST VALUE</div>
                </div>
                <div className="plan">
                    <h3>{isAnnual ? '$179/year' : '$19/month'}</h3>
                    <p className="old-price">{isAnnual ? '$225/year' : '$25/month'}</p>
                    <ul>
                        <li>Basic AI</li>
                        <li>Advanced AI (soon)</li>
                        <li>15 listings/month</li>
                        <li>Max 15 images/listing</li>
                        <li>Ad free</li>
                        <li>Cancel anytime</li>
                    </ul>
                    <button onClick={() => handleStartNow('premium')}>Subscribe</button>
                </div>
                <div className="plan">
                    <h3>Need something else?</h3>
                    <p>Need more pictures or more listings? Contact us to create a custom plan!</p>
                    <button onClick={() => navigate('/contactus')}>Contact Us</button>
                </div>
            </div>
        </main>
    );
};

export default Subscribe;
