import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Adjust the path if necessary
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import './Signup.css';

const Signup = ({ isUserAuthenticated, onSignupSuccess }) => {
    const [fullName, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== passwordConfirm) {
            alert("Passwords don't match.");
            return;
        }

        try {
            const userCredential = await auth.createUserWithEmailAndPassword(email, password);
            const user = userCredential.user;

            const response = await fetch('https://realtorai-3955e.uc.r.appspot.com/api/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: user.uid,
                    fullName: fullName,
                    email: email,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to save user data');
            }

            // setTimeout(() => {
                onSignupSuccess();
                navigate('/dashboard');
            // }, 1250);
        } catch (error) {
            console.error('Error signing up:', error);
            alert(error.message);
        }
    };


    const handleGoogleSignup = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            const userExistsResponse = await fetch(`https://realtorai-3955e.uc.r.appspot.com/api/checkUser/${user.uid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (userExistsResponse.ok) {
                const data = await userExistsResponse.json();
                if (data.userExists) {
                    alert('You are already registered. Logging you instead.');
                     // Redirect to a different page if already registered
                    // setTimeout(() => {
                        navigate('/dashboard');
                    // }, 1250);
                    return;
                }
            }


            const response = await fetch('https://realtorai-3955e.uc.r.appspot.com/api/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: user.uid,
                    fullName: user.displayName,
                    email: user.email,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to save user data');
            }

            // setTimeout(() => {
                onSignupSuccess();
                navigate('/dashboard');
            // }, 2000);
        } catch (error) {
            console.error('Error during Google Sign-In:', error);
            alert(error.message);
        }
    };

    useEffect(() => {
        console.log("inside use effect");
        if (isUserAuthenticated) {
          navigate('/dashboard');
        }
      }, [isUserAuthenticated, navigate]);

    return (
        <div className="signup-container">
            <div className="signup-form-container">
                <h1 className="signup-title">Register</h1>

                <button className="google-signup-btn" onClick={handleGoogleSignup}>Sign Up with Google</button>

                {/* <button className="apple-login-btn">Log in with </button> */}
                <div className="divider">— Or sign up with email —</div>

                <form onSubmit={handleSubmit}>
                    <input
                        type="name"
                        value={fullName}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Full Name"
                        className="signup-input"
                    />
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        className="signup-input"
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        className="signup-input"
                    />
                    <input
                        type="password"
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        placeholder="Confirm Password"
                        className="signup-input"
                    />
                    <button type="submit" className="signup-btn">Sign Up</button>
                </form>
                {/* <Link to="/login" className="login-linkHolder"><a href="#" className="login-link">Already have an account? Log In!</a></Link> */}
                <Link to="/login" className="login-link">Already have an account? Log In!</Link>

            </div>
        </div>
    );
};

export default Signup;
