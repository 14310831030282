import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import './Map.css'; // Add CSS for map sizing

mapboxgl.accessToken = 'pk.eyJ1IjoiYW5pcmJhbmsiLCJhIjoiY2xyZnRuaWdzMDUwbzJ0bzF5ZDRiY3p3YyJ9.0_NSPuoHYOCGE1K84qOYyA';

const MapComponent = ({ lat, lng }) => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v12', // Map style
      center: [lng, lat],
      zoom: 15,
      interactive: false
    });

    // Add navigation control (zoom in/out)
    new mapboxgl.Marker()
      .setLngLat([lng, lat]) // Marker position using longitude and latitude
      .addTo(map);

    return () => map.remove(); // Cleanup on unmount
  }, [lat, lng]); // Dependencies for useEffect

  return <div className="map-container" ref={mapContainerRef} />;
};

export default MapComponent;
