import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Dashboard.css';
import createIcon from '../images/plus_circle.png'; // Replace with the path to your icon
import deleteIcon from '../images/x_delete.png'; // Replace with the path to your icon
import MultiStepForm from '../MultiStepForm'; // Import the MultiStepForm component
import { auth } from '../firebase'; // Adjust the import path if necessary
import Map from './Map'; // Import the Map component you created
import { loadStripe } from '@stripe/stripe-js';
import 'mapbox-gl/dist/mapbox-gl.css';
import getCoordinatesFromAddress from './getCoordinatesFromAddress'; // Adjust the path to where your function is located
import { fetchUserSubscriptionStatus, fetchListingsDatabase, deleteListingDatabase, fetchUserData, fetchPayAsYouGoStatus, checkUnusedSessions, getUnusedSession} from '../api';

//LIVE
const stripePromise = loadStripe('pk_live_51OZjujGyJ9uIkcUsMAtvq0FCcIOUyitO9M2onkMfgVp2khpoTzt9YY1zMijzaVdZTy3XXVInhfwuNhVQm63ATF3000CUVncLjK');

//TEST
// const stripePromise = loadStripe('pk_test_51OZjujGyJ9uIkcUsRoSAO1FeoTmRzPkig3V05nJtYetcc0scPxLHGpUKei7BK4BW6yrGON9SZmUvGXtYPHnt00fB002VKPXxTP');

const Dashboard = () => {
  const [showForm, setShowForm] = useState(false);
  const [listings, setListings] = useState([]);
  const [listingIds, setListingIds] = useState([]); // Add this line
  const [listingCount, setListingCount] = useState(0);
  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [hasUsedFreeCreation, setHasUsedFreeCreation] = useState(false);
  const [hasPayAsYouGo, setHasPayAsYouGo] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();


  const checkUserSubscriptionStatus = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const subscription = await fetchUserSubscriptionStatus(user.uid);
        return subscription && subscription.status === 'active';
      } catch (error) {
        console.error("Error fetching subscription: ", error);
        return false;
      }
    }
    return false;
  };

  const handleCreateListingClick = async () => {
    const user = auth.currentUser;

    if (user) {
      const subscriptionActive = await checkUserSubscriptionStatus();
      const userData = await fetchUserData(user.uid); // This function should fetch user data including hasUsedFreeCreation
      const fullName = userData.fullName;
      const email = userData.email; 

      if (subscriptionActive || (!subscriptionActive && !userData.hasUsedFreeCreation)) {
        setShowForm(true);
      } else if (hasPayAsYouGo) { 
        const hasListingsAvailable = await checkUnusedSessions(user.uid);

        if (hasListingsAvailable.hasListings) {
          const sessions = await getUnusedSession(user.uid);
          const sessionID = Object.keys(sessions)[0];
          setSessionId(sessionID);
          setShowForm(true);
        } else {
          initiateStripeCheckout(user.uid, fullName, email); 
        }
        console.log("hasListingsAvailable: ", hasListingsAvailable.hasListings);
      } else {
        navigate('/subscribe');
      }
    }
  };

  const initiateStripeCheckout = async (userId, fullName, email) => {
    // Call your backend to create a Stripe Checkout session
    const response = await fetch('https://realtorai-3955e.uc.r.appspot.com/api/create-checkout-session-onetime', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: userId, fullName: fullName, email: email}),
    });
    const session = await response.json();
    const stripe = await stripePromise;
    stripe.redirectToCheckout({ sessionId: session.sessionId });
  };
  

  useEffect(() => {
    document.title = 'The Listly - Dashboard';


    const getUserFreeListing = async () => {  
      const user = auth.currentUser;
      const userData = await fetchUserData(user.uid);
      console.log(userData.hasUsedFreeCreation);
      setHasUsedFreeCreation(userData.hasUsedFreeCreation);
    };
    getUserFreeListing();
    
    const fetchListings = async () => {
      const user = auth.currentUser;
      if (user) {
        // Fetch subscription plan
        const subscription = await fetchUserSubscriptionStatus(user.uid);
        const subscriptionPlan = subscription.adsBasicOrPremium;

        if (subscriptionPlan) {
          setSubscriptionPlan(subscriptionPlan);
        }

        const payAsYouGo = await fetchPayAsYouGoStatus(user.uid);
        const payAsYouGoStatus = payAsYouGo.payAsYouGoPlan;
        if (payAsYouGoStatus === true) {
          setHasPayAsYouGo(true);
        }

        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();

        const listingsData = await fetchListingsDatabase(user.uid);

        if (listingsData) {
          setListings(Object.values(listingsData));
          setListingIds(Object.keys(listingsData));
          const monthlyListings = Object.values(listingsData).filter(listing => {
            const listingMonth = new Date(listing.dateAdded).getMonth();
            const listingYear = new Date(listing.dateAdded).getFullYear();
            return listingMonth === currentMonth && listingYear === currentYear;
          });
          setListingCount(monthlyListings.length);

          for (const [id, listing] of Object.entries(listingsData)) {
            console.log(id)
            const address = `${listing.street}, ${listing.city}, ${listing.state}, ${listing.zipcode}`;
            const coords = await getCoordinatesFromAddress(address);
            setListings(prevListings => prevListings.map(l => l === listing ? { ...l, coordinates: coords } : l));
          }
        }
      }
    };

    fetchListings();

    const verifyPaymentSession = async () => {
      const queryParams = new URLSearchParams(location.search);
      const sessionIdFromQuery = queryParams.get('session_id');
    
      if (sessionIdFromQuery) {
        try {
          const response = await fetch(`https://realtorai-3955e.uc.r.appspot.com/api/verify-payment-session?session_id=${sessionIdFromQuery}`);
          const result = await response.json();
    
          if (result && result.success) {
            setSessionId(sessionIdFromQuery);
            setShowForm(true);
          }
        } catch (error) {
          console.error("Error verifying payment session: ", error);
          // Handle error
        }
      }
    };
    
    verifyPaymentSession();
    

  }, [location]);

  const deleteListing = async (listingId) => {
    const user = auth.currentUser;
    if (user) {
      try {
        await deleteListingDatabase(user.uid, listingId);

        setListings(listings.filter((_, index) => listingIds[index] !== listingId));
        setListingIds(listingIds.filter(id => id !== listingId));
      } catch (error) {
        console.error("Error deleting listing: ", error);
      }
    }
  };


  const RemainingListingsTooltip = () => {
    if (subscriptionPlan === 'ads') {
      const remainingListings = Math.max(0, 5 - listingCount);
      return <div className="remaining-listings-tooltip">Remaining listings this month: {remainingListings}</div>;
    }
    else if (subscriptionPlan === 'basic') {
      const remainingListings = Math.max(0, 10 - listingCount);
      return <div className="remaining-listings-tooltip">Remaining listings this month: {remainingListings}</div>;
    }
    else if (subscriptionPlan === 'premium') {
      const remainingListings = Math.max(0, 15 - listingCount);
      return <div className="remaining-listings-tooltip">Remaining listings this month: {remainingListings}</div>;
    }
    return null;
  };

  return (
    <main className="dashboard">
      {showForm ? (
        <MultiStepForm 
        onClose={() => setShowForm(false)} 
        subscriptionPlan={subscriptionPlan} 
        sessionId={sessionId}  // Make sure this matches the state variable name
      />
      ) : (
        <>
          <div className="dashboard-header">
            <h1 className="dashboard-title">My Dashboard</h1>
            <button className="create-listing-button" onClick={handleCreateListingClick}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}>
              <img src={createIcon} alt="Create listing" />
              {showTooltip && <RemainingListingsTooltip />}
            </button>
          </div>

          <div className={`listings-container ${listings.length === 0 ? 'empty' : ''}`}>
            {listings.length > 0 ? (
              listings.map((listing, index) => (
                <div key={listingIds[index]} className="property-listing-card" onClick={() => navigate(`/listing/${auth.currentUser.uid}/${listingIds[index]}`)}>
                  <div className="card-content">
                    <h2>Listing {index + 1}</h2>
                    <div className="map-holder">
                      {listing.coordinates && <Map lat={listing.coordinates.lat} lng={listing.coordinates.lng} />}
                    </div>
                    <p>{listing.street}<br></br> {listing.city}, {listing.state}</p>
                  </div>
                  {/* <button className="delete-button" onClick={(e) => {
                    e.stopPropagation(); 
                    deleteListing(listingIds[index]);
                  }}>
                    <img src={deleteIcon} alt="Delete" />
                  </button> */}
                </div>
              ))
            ) : !hasUsedFreeCreation ? (
              <p>One free listing available!</p>
            ) : (
              <p>No listings found</p>
            )}
          </div>
        </>
      )}
    </main>
  );

}

export default Dashboard;