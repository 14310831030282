// hooks.js
// Custom hooks for the application

import { useState, useEffect } from 'react';
import { auth } from './firebase'; // Ensure this path is correct for your Firebase setup

/**
 * Custom hook to manage authentication state.
 * @returns {Object} Current user object and a loading state.
 */
export const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return { currentUser, isLoading };
};
