
// export const uploadImagesToS3 = async (files) => {
//     const formData = new FormData();
//     files.forEach(file => formData.append('images', file));
  
//     try {
//       const response = await fetch('http://localhost:3001/upload-images', {
//         method: 'POST',
//         body: formData,
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to upload images to S3');
//       }
  
//       const data = await response.json();
//       return data.imageUrls;
//     } catch (error) {
//       console.error('Error uploading images to S3:', error);
//       throw error;
//     }
//   };
  
  export const uploadImagesToS3 = async (files) => {
    try {
        return await Promise.all(files.map(async (file) => {
            // Request presigned URL from your server
            const res = await fetch('https://realtorai-3955e.uc.r.appspot.com/generate-presigned-url');
            const { presignedData, imageUrl } = await res.json();

            // Use FormData to send the file to S3
            const formData = new FormData();
            Object.entries(presignedData.fields).forEach(([k, v]) => {
                formData.append(k, v);
            });
            formData.append('file', file);

            // Upload to S3
            const uploadRes = await fetch(presignedData.url, {
                method: 'POST',
                body: formData,
            });

            if (uploadRes.ok) {
                return imageUrl;
            } else {
                throw new Error('Upload to S3 failed.');
            }
        }));
    } catch (error) {
        console.error('Error uploading images:', error);
        throw error;
    }
};

  /**
   * Processes images with Vision API.
   * @param {string[]} imageUrls - URLs of the images to be processed.
   * @returns {Promise<any>} - Promise resolving to processed data from Vision API.
   */
  export const processImagesWithVisionApi = async (imageUrls) => {
    try {
      const response = await fetch('https://realtorai-3955e.uc.r.appspot.com/process-vision', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ imageUrls }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to process images with Vision API');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Error processing images with Vision API:', error);
      throw error;
    }
  };
  
  /**
   * Generates a description based on form data and Vision API data.
   * @param {Object} formData - Form data from the user.
   * @param {Object} visionData - Data returned from the Vision API.
   * @returns {Promise<string>} - Promise resolving to a generated description.
   */
  export const generateDescription = async (formData, visionData) => {
    try {
      const response = await fetch('https://realtorai-3955e.uc.r.appspot.com/generate-description', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ formData, visionData }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to generate description');
      }
  
      const data = await response.json();
      return data.description;
    } catch (error) {
      console.error('Error generating description:', error);
      throw error;
    }
  };
  
  export const uploadToFirebase = async (combinedData, uid) => {
    try {
      const response = await fetch('https://realtorai-3955e.uc.r.appspot.com/api/upload-listing', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ combinedData, uid }),
      });

      if (!response.ok) {
          throw new Error('Failed to upload data to Firebase');
      }

      const data = await response.json();
      return data.listingKey;
  } catch (error) {
      console.error('Error uploading data to Firebase:', error);
      throw error;
  }
  };
  

  export const fetchUserSubscriptionStatus = async (uid) => {
    try {
        const response = await fetch(`https://realtorai-3955e.uc.r.appspot.com/api/subscriptions/${uid}`);
        
        if (!response.ok) {
            throw new Error('Failed to fetch subscription status');
        }
        return await response.json();
    } catch (error) {
        console.error("Error fetching subscription: ", error);
        throw error;
    }
};

export const fetchListingsDatabase = async (uid) => {
  try {
      const response = await fetch(`https://realtorai-3955e.uc.r.appspot.com/api/listings?uid=${uid}`);
      if (!response.ok) {
          throw new Error('Failed to fetch listings');
      }
      return await response.json();
  } catch (error) {
      console.error("Error fetching listings: ", error);
      throw error;
  }
};

export const deleteListingDatabase = async (uid, listingId) => {
  try {
      const response = await fetch(`https://realtorai-3955e.uc.r.appspot.com/api/listing/${uid}/${listingId}`, { method: 'DELETE' });
      if (!response.ok) {
          throw new Error('Failed to delete listing');
      }
  } catch (error) {
      console.error("Error deleting listing: ", error);
      throw error;
  }
};
export const fetchUserData = async (uid) => {
  try {
      const response = await fetch(`https://realtorai-3955e.uc.r.appspot.com/api/user/${uid}`);
      if (!response.ok) {
          throw new Error('Failed to fetch user data');
      }
      return await response.json();
  } catch (error) {
      console.error("Error fetching user data: ", error);
      throw error;
  }
};

export const updateHasUsedFreeCreation = async (uid) => {
  try {
      const response = await fetch(`https://realtorai-3955e.uc.r.appspot.com/api/updateFreeUsage`, {
          method: 'POST', 
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid }), // Include the uid in the request body
      });
      if (!response.ok) {
          throw new Error('Failed to update user data');
      }
      const responseData = await response.json();
      return responseData; // Optionally return the response data
  } catch (error) {
      console.error("Error updating user data: ", error);
      throw error;
  }
}

export const unsubscribeUser = async (uid) => {
  console.log(uid); 
  try {
    const response = await fetch('https://realtorai-3955e.uc.r.appspot.com/api/unsubscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uid }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error unsubscribing: ", error);
    throw error;
  }
}

export const resubscribeUser = async (uid) => {
  console.log(uid); 
  try {
    const response = await fetch('https://realtorai-3955e.uc.r.appspot.com/api/resubscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uid }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error resubscribing: ", error);
    throw error;
  }
}

export const payAsYouGoSubscription = async (uid) => {
  console.log(uid); 
  try {
    const response = await fetch('https://realtorai-3955e.uc.r.appspot.com/api/userPayAsYouGo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uid }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error resubscribing: ", error);
    throw error;
  }
}

export const fetchPayAsYouGoStatus = async (uid) => {
  try {
      const response = await fetch(`https://realtorai-3955e.uc.r.appspot.com/api/fetchPayAsYouGo/${uid}`);
      
      if (!response.ok) {
          throw new Error('Failed to fetch pay as you go status');
      }
      return await response.json();
  } catch (error) {
      console.error("Error fetching pay as you go status: ", error);
      throw error;
  }
};

export const checkUnusedSessions = async (userId) => {
   try {
      const response = await fetch(`https://realtorai-3955e.uc.r.appspot.com/api/haslistings/${userId}`);

      return await response.json();
   } catch (error) {
      console.error("Error checking unused sessions: ", error);
      throw error;
   }
};

export const getUnusedSession = async (userId) => {
  try {
     const response = await fetch(`https://realtorai-3955e.uc.r.appspot.com/api/getlistingsessionid/${userId}`);

     return await response.json();
  } catch (error) {
     console.error("Error checking unused sessions: ", error);
     throw error;
  }
};

