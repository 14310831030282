// PropertyType.js
import React from 'react';

const PropertyType = ({ formData, handleChange, nextStep, prevStep }) => {
  // const propertyTypes = ["House", "Townhome", "Condo", "Apartment", "Vacant/Land", "Multi Family"];
  return (
    <div className="form-step-container">
      <div className="form-header-container-transaction">
        <button className="back-button" onClick={prevStep}></button>
        <h2 className="form-title">Property Type</h2>
        <button className="next-button" onClick={nextStep}></button>
      </div>
      <div className="radio-group">
        <input
          type="radio"
          id="house"
          name="propertyType"
          value="House"
          className="radio-button"
          checked={formData.propertyType === 'House'}
          onChange={handleChange('propertyType')}
        />
        <label htmlFor="house" className="radio-label">
          House
        </label>

        <input
          type="radio"
          id="townhome"
          name="propertyType"
          value="Townhome"
          className="radio-button"
          checked={formData.propertyType === 'Townhome'}
          onChange={handleChange('propertyType')}
        />
        <label htmlFor="townhome" className="radio-label">
          Townhome
        </label>

        <input
          type="radio"
          id="condo"
          name="propertyType"
          value="Condo"
          className="radio-button"
          checked={formData.propertyType === 'Condo'}
          onChange={handleChange('propertyType')}
        />
        <label htmlFor="condo" className="radio-label">
          Condo
        </label>

        <input
          type="radio"
          id="apartment"
          name="propertyType"
          value="Apartment"
          className="radio-button"
          checked={formData.propertyType === 'Apartment'}
          onChange={handleChange('propertyType')}
        />
        <label htmlFor="apartment" className="radio-label">
         Apartment
        </label>

        <input
          type="radio"
          id="multi-family"
          name="propertyType"
          value="Multi-Family"
          className="radio-button"
          checked={formData.propertyType === 'Multi-Family'}
          onChange={handleChange('propertyType')}
        />
        <label htmlFor="multi-family" className="radio-label">
          Multi-Family 
        </label>

        <input
          type="radio"
          id="vacant-land"
          name="propertyType"
          value="Vacant/Land"
          className="radio-button"
          checked={formData.propertyType === 'Vacant/Land'}
          onChange={handleChange('propertyType')}
        />
        <label htmlFor="vacant-land" className="radio-label">
          Vacant/Land
        </label>

      </div>
    </div>
  );
};

export default PropertyType;
