import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import logo from '../images/realtorai-high-resolution-logo-transparent.png'; // Replace with the path to your icon
import logo from '../images/the-listly-high-resolution-logo-transparent.png'; // Replace with the path to your icon
import person from '../images/person.png'; // Replace with the path to your icon
import './Header.css';

const Header = ({ isLoggedIn, user, onSignOut }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const signOut = () => {
    onSignOut();
  };

  const handleHover = () => {
    if (isLoggedIn && user) {
      setIsDropdownVisible(true);
    }
  };

  const handleMouseLeave = () => {
    setIsDropdownVisible(false);
  };

  return (
    <header className="header">
      <Link to="/">
        <img src={logo} alt="The Listly Logo" className="logo" />
      </Link>
      <nav>
        <ul className="nav-links">
          <li><Link to="/faq">FAQ</Link></li>
          <li><Link to="/contactus">Contact Us</Link></li>
          <li>
            {isLoggedIn && user ? (
              <div onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}>
                <Link to="/dashboard" className="profile-icon">
                  <img src={person} alt="Profile" className="profile-image" />
                </Link>
                {isDropdownVisible && (
                  <div className="dropdown-menu">
                    <Link to="/dashboard">My Dashboard</Link>
                    <Link to="/settings">Settings</Link>
                    <Link to="/" onClick={signOut}>Sign Out</Link>
                  </div>
                )}
              </div>
            ) : (
              <Link to="/login" className="btn-login">Log In</Link>
            )}
          </li>

        </ul>
      </nav>
    </header>
  );
}

export default Header;
