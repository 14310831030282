import React, { useState } from 'react';
import {
  PropertyAddress,
  BasicAttributes,
  TransactionType,
  PropertyType,
  PropertyDescription,
  PropertyPictures,
} from './steps';
import { useNavigate } from 'react-router-dom'; // Make sure to import useNavigate
import './MultiStepForm.css';
import deleteIcon from './images/delete.png';
import { uploadImagesToS3, processImagesWithVisionApi, generateDescription, uploadToFirebase, updateHasUsedFreeCreation } from './api';
import { useAuth } from './hooks';

const totalSteps = 6;
const initialFormData = {
  street: '',
  unit: '',
  city: '',
  state: '',
  zipcode: '',
  bedrooms: '',
  bathrooms: '',
  squareFootage: '',
  lotSize: '',
  yearBuilt: '',
  transactionType: '',
  propertyType: '',
  description: '',
  pictures: []
};

const MultiStepForm = ({ onClose, subscriptionPlan, sessionId = null }) => {
  const navigate = useNavigate(); // Hook for navigating programmatically

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const { currentUser } = useAuth();

  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);
  const handleChange = input => e => setFormData({ ...formData, [input]: e.target.value });

  // const handleFileChange = files => setFormData(prev => ({ ...prev, pictures: [...prev.pictures, ...files] }));
  const handleFileChange = (newFiles) => setFormData(prev => ({ ...prev, pictures: newFiles }));


  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (formData.pictures.length === 0) {
        const imageUrls = [];
        setStatusMessage('Generating property description (1/2)...');
        const description = await generateDescription(formData, null);
  
        const today = new Date();
        const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  
        setStatusMessage('Saving listing (2/2)...');
        const listingId = await uploadToFirebase({
          ...formData,
          aiDescription: description,
          imageUrls,
          dateAdded: formattedDate  // Add the formatted date here
        }, currentUser.uid);
  
  
        setStatusMessage('Submission complete.');
        await updateHasUsedFreeCreation(currentUser.uid);

        if (sessionId) {
          await removeUnusedSession(sessionId, currentUser.uid);
        }

        navigate(`/listing/${currentUser.uid}/${listingId}`);
      } else{
        setStatusMessage('Uploading images (1/4)...');
        const imageUrls = await uploadImagesToS3(formData.pictures);
  
        setStatusMessage('Analyzing images (2/4)...');
        const visionData = await processImagesWithVisionApi(imageUrls);
  
        setStatusMessage('Generating property description (3/4)...');
        const description = await generateDescription(formData, visionData);
  
        const today = new Date();
        const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  
        setStatusMessage('Saving listing (4/4)...');
        const listingId = await uploadToFirebase({
          ...formData,
          aiDescription: description,
          imageUrls,
          dateAdded: formattedDate  // Add the formatted date here
        }, currentUser.uid);
  
  
        setStatusMessage('Submission complete.');
        await updateHasUsedFreeCreation(currentUser.uid);

        if (sessionId) {
          await removeUnusedSession(sessionId, currentUser.uid);
        }
        navigate(`/listing/${currentUser.uid}/${listingId}`);
      }
      
      // Navigate to the listing details page using the uid and listingId
    } catch (error) {
      console.error('Submission error:', error);
      setStatusMessage('Error during submission. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const removeUnusedSession = async (sessionId, userId) => {
    try {
      await fetch(`https://realtorai-3955e.uc.r.appspot.com/api/remove-unused-session/${userId}/${sessionId}`, {
        method: 'DELETE',
      });
      console.log(`Session ID ${sessionId} removed for user ${userId}`);
    } catch (error) {
      console.error('Error removing unused session ID: ', error);
    }
  };
  


  const renderStep = () => {
    const stepComponents = {
      1: <PropertyAddress formData={formData} handleChange={handleChange} setFormData={setFormData} nextStep={nextStep} />,
      2: <BasicAttributes formData={formData} handleChange={handleChange} nextStep={nextStep} prevStep={prevStep} />,
      3: <TransactionType formData={formData} handleChange={handleChange} nextStep={nextStep} prevStep={prevStep} />,
      4: <PropertyType formData={formData} handleChange={handleChange} nextStep={nextStep} prevStep={prevStep} />,
      5: <PropertyDescription formData={formData} handleChange={handleChange} nextStep={nextStep} prevStep={prevStep} />,
      6: <PropertyPictures formData={formData} handleFileChange={handleFileChange} prevStep={prevStep} handleSubmit={handleSubmit} subscriptionPlan={subscriptionPlan}/>
    };
    return stepComponents[currentStep] || stepComponents[1];
  };

  const progressPercentage = ((currentStep - 1) / (totalSteps - 1)) * 100;

  return (
    <>
      {isLoading ? (
        <div className='loading-container'>
          <div className="inner-loading-container">
            <div className="spinner"></div>
            <p className='statusMessage'>{statusMessage}</p>
          </div>
        </div>
      ) : (
        <>
          <div className="form-header">
            <div className="cancel-button-container">
              <button className="cancel-button" onClick={onClose}>
                <img src={deleteIcon} alt="Cancel" className="button-icon" />
                Cancel
              </button>
            </div>
          </div>

          <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
          </div>
          <div className="multi-step-form">
            {renderStep()}
          </div>
        </>
      )}
    </>
  );
};

export default MultiStepForm;